import React from 'react';

const RankProgress = ({ images, cta, nextRank, barWidth }) => {
  const barStyles = {
    width: `${barWidth}%`,
  };

  const dotStyles = {
    left: `${Math.ceil(barWidth - 8)}%`,
  };

  return (
    <div>
      <div className="th-rank-progress">
        <img alt="" src={images.hikerUrl} className="" />
        <div className="th-rank-progress__bar-container">
          <div className="th-rank-progress__bar" style={barStyles} />
          <div className="th-rank-progress__finish-dot" />
          <div className="th-rank-progress__progress-dot" style={dotStyles} />
        </div>
        <img alt="" src={images.scoutUrl} className="" />
      </div>
      <div className="slds-text-align_center slds-m-top_xx-small">
        <div>{cta}</div>
        <div className="tds-text_bold">{nextRank}</div>
      </div>
    </div>
  );
};

export default RankProgress;
