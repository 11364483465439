import React from 'react';
import PropTypes from 'prop-types';
import railsFetch from 'lib/railsFetch';
import range from 'lodash/range';
import { t, I18n } from 'lib/I18n';
import { autoFollowTrailmix } from 'lib/followTrailmix';

import IconContext from 'components/utils/IconContext';
import { Modal, Settings } from '@salesforce/design-system-react';
import { SOURCE_QUIZ_ATTEMPTED } from 'actions/trailmix/events_constants';

import Question from './Question';
import ChallengeError from './ChallengeError';
import ChallengeHeader from './ChallengeHeader';
import ChallengeFooter from './ChallengeFooter';

Settings.setAppElement('#main-wrapper');

export default class Quiz extends React.Component {
  static propTypes = {
    apiName: PropTypes.string.isRequired,
    content_uid: PropTypes.string.isRequired,
    authenticated: PropTypes.bool.isRequired,
    questions: PropTypes.array.isRequired,
    points: PropTypes.number.isRequired,
    pointsNext: PropTypes.number.isRequired,
    completeChallenge: PropTypes.func.isRequired,
    attempts: PropTypes.number.isRequired,
    isModal: PropTypes.bool,
    parent_trailmix: PropTypes.object,
    isTrailheadOnPlatform: PropTypes.bool,
    shouldRenderToS: PropTypes.bool,
    // Marketing OptIn checkbox should only be rendered
    // if the TermsOfService are rendered and
    // if the marketing flag is set in the query params to support
    // multiple versions of core
    shouldRenderMarketingOptIn: PropTypes.bool,
    initialSelection: PropTypes.object,
    initialCorrect: PropTypes.array,
    skipFooter: PropTypes.bool,
  }

  static defaultProps = {
    isModal: false,
    initialSelection: {},
    initialCorrect: [],
    skipFooter: false,
  }

  state = {
    selection: this.props.initialSelection,
    mismatched: [],
    correct: this.props.initialCorrect,
    disabled: !this.props.authenticated,
    points: this.props.points,
    pointsNext: this.props.pointsNext,
    submitted: false,
    attempts: this.props.attempts,
    submitError: false,
    rate_limited: false,
    tosAgreed: !this.props.shouldRenderToS,
    marketingOptInAgreed: false // Marketing OptIn by default is not checked
  }

  onToSChange = () => {
    this.setState({
      tosAgreed: !this.state.tosAgreed
    });
  }

  onMarketingOptInChange = () => {
    this.setState({
      marketingOptInAgreed: !this.state.marketingOptInAgreed
    });
  }

  onSelectOption = (q, a) => {
    const option = {};
    option[q] = a;

    const mismatches = this.newMismatched(q);

    this.setState({
      selection: {
        ...this.state.selection,
        ...option,
      },
      mismatched: mismatches,
      submitted: mismatches.length !== 0
    });
  }

  onQuizSubmit = (e) => {
    e.preventDefault();
    const {selection, disabled} = this.state;

    if (disabled) {
      return;
    }

    this.setState({
      submitted: true,
      submitError: false
    });

    let localePath = I18n.locale == 'en' ? '' : `${I18n.locale}/`;

    autoFollowTrailmix(this.props.parent_trailmix, SOURCE_QUIZ_ATTEMPTED);

    railsFetch({
      url: `/${localePath}quizzes/${this.props.content_uid}?api_name=${this.props.apiName}`,
      method: 'post',
      data: {
        isTrailheadOnPlatform: this.props.isTrailheadOnPlatform,
        marketingOptInAgreed: this.state.marketingOptInAgreed,
        answers: selection
      }
    }).then((response) => this.onQuizSuccess(response))
      .catch(() => {
        this.setState({
          submitError: true
        });
      });
  }

  onQuizSuccess = (response) => {
    if (response.completed) {
      this.onQuizComplete(response.points_earned, response.rewards);
    } else {
      this.renderMismatches(response.mismatched);
      this.updateQuiz(response.attempts, response.points_available,
        response.points_available_next, response.rate_limited);
    }
  }

  onQuizComplete = (points, rewards) => {
    this.props.completeChallenge(true, points, rewards);
  }

  updateQuiz = (attempts, points, pointsNext, rate_limited) => {
    this.setState({
      ...this.state,
      attempts,
      points,
      pointsNext,
      rate_limited
    });
  }

  newMismatched = (q) => (
    this.state.mismatched.filter((v) => v !== q.toString())
  )

  isError = (index) => (
    this.state.mismatched.includes(index.toString())
  )

  isCorrect = (index) => (
    this.state.correct.includes(index.toString())
  )

  isSubmitDisabled = () => {
    /* eslint-disable max-len */
    const tosChecked = this.props.shouldRenderToS ? this.props.shouldRenderToS && this.state.tosAgreed : true;

    /* eslint-disable max-len */

    // disabled if
    // tos is required but unChecked
    // there is no selection
    // there are mismatches from a previous submit
    // or the quiz is actively submitting
    return !tosChecked || Object.keys(this.state.selection).length !== this.props.questions.length ||
    this.state.mismatched.length > 0 || this.state.submitted;
  }

  calculateCorrect = (mismatched) => (
    range(this.props.questions.length).map(
      (v) => v.toString()
    ).filter(
      (v) => !mismatched.includes(v)
    )
  )

  showErrors = () => (
    this.state.mismatched.length > 0 && this.state.submitted
  )

  renderMismatches = (mismatched) => {
    this.setState({
      mismatched,
      correct: this.calculateCorrect(mismatched),
      submitted: true
    });
  }

  renderMarketingOptIn = () => {
    if (!this.props.shouldRenderMarketingOptIn) { return ''; }
    return (
      <div className="th-quiz-marketing__checkbox-container">
        <input
          className="th-quiz-marketing__checkbox-input"
          type="checkbox"
          onChange={this.onMarketingOptInChange}
          id="marketing_opt_in" />
        <label className="th-quiz-marketing__checkbox-label tds-color_meteorite" htmlFor="marketing_opt_in">{t('top_marketing_opt_in.label')}</label>
      </div>
    );
  }

  renderToS = () => {
    if (!this.props.shouldRenderToS) { return ''; }
    return (
      <div className="th-quiz-tos">
        <p className="slds-text-heading_small tds-text_bold">{t('terms_of_service.body')}</p>

        <div className="th-quiz-tos__checkbox-container">
          <input
            className="th-quiz-tos__checkbox-input"
            type="checkbox"
            onChange={this.onToSChange}
            id="tos" />
          <label className="th-quiz-tos__checkbox-label" htmlFor="tos" dangerouslySetInnerHTML={{__html: t('terms_of_service.label')}} />
        </div>

        {this.renderMarketingOptIn()}
      </div>
    );
  }

  renderQuestion = (question, idx) => (
    <Question
      key={`idx_${idx}`}
      index={idx}
      question={question}
      onSelectOption={this.onSelectOption}
      disabled={this.state.disabled}
      error={this.isError(idx)}
      correct={this.isCorrect(idx)}
      initialSelection={this.props.initialSelection ? this.props.initialSelection[idx] : null}
    />
  )

  renderErrors = () => {
    let text;

    if (!this.state.rate_limited) {
      text = t('challenge.quiz.wrong_answers', {count: this.state.mismatched.length});
    } else {
      text = t('challenge.quiz.rate_limit');
    }

    return (
      <ChallengeError text={text} errors={this.state.mismatched} />
    );
  }

  renderMessage = () => {
    if (this.state.submitError) {
      return (
        <span className="th-color--destructive">
          {t('challenge.quiz.error')}
        </span>
      );
    }

    return (
      this.showErrors() ? this.renderErrors() : this.renderNext()
    );
  }

  renderHelperText = () => (
    <div className="th-challenge__footer-text">
      {this.renderMessage()}
    </div>
  )

  renderNext = () => {
    switch (this.state.attempts) {
      case 0:
        return (
          t('challenge.quiz.check_description.first', {
            second_attempt: this.state.pointsNext,
            third_attempt: Math.floor(this.state.pointsNext / 2)
          })
        );
      case 1:
        return (
          t('challenge.quiz.check_description.second', {
            third_attempt: this.state.pointsNext
          })
        );
      default:
        return '';
    }
  }

  renderHeader() {
    const {points} = this.state;

    return (
      <ChallengeHeader
        points={points}
        heading={t('challenge.quiz.title')}
      />
    );
  }

  renderFooterContent(options) {
    const {authenticated, skipFooter} = this.props;
    const {points} = this.state;

    if (skipFooter) return null;

    return (
      <ChallengeFooter
        points={points}
        authenticated={authenticated}
        isSubmitDisabled={this.isSubmitDisabled}
        renderHelperText={this.renderHelperText}
        type="quiz"
        unitType="unit"
        {...options}
      />
    );
  }

  renderQuestions = () => this.props.questions.map(this.renderQuestion)

  renderModal() {
    const {quiz, onClose, isModalOpen} = this.props;

    return (
      <IconContext>
        <Modal
          ref={(el) => { this.modal = el; }}
          containerClassName="slds-container--large quiz-modal sb-challenge-modal"
          directional
          dismissible
          footer={
            this.renderFooterContent({onSubmit: this.onQuizSubmit})
          }
          headerClassName="slds-text-align--center"
          isOpen={isModalOpen}
          title={quiz.title}
          onRequestClose={onClose}>
          <div className="th-quiz">
            <form>
              {this.renderQuestions()}
            </form>
          </div>
        </Modal>
      </IconContext>
    );
  }

  render() {
    const {isModal} = this.props;

    if (isModal) {
      return this.renderModal();
    }

    return (
      <IconContext>
        <section className="th-challenge slds-m-top--xx-large th-quiz">
          <form action="" onSubmit={this.onQuizSubmit}>
            {this.renderHeader()}
            {this.renderQuestions()}
            {this.renderToS()}
            {this.renderFooterContent()}
          </form>
        </section>
      </IconContext>
    );
  }
}
