import React from 'react';
import { Icon, Button } from '@devforce/tds-react';
import IconContext from 'components/utils/IconContext';

const FirstStep = ({
  isCompleted,
  isDisabled,
  imgUrl,
  title,
  subtitle,
  section,
  btnText,
  onButtonClick,
  lastChild,
}) => {
  const iconPositionStyles = {
    position: 'absolute',
    top: '-2px',
    right: '-9px',
    border: '3px solid white',
    borderRadius: '100%',
  };

  const dottedTrail = <div className="tds-dotted-trail" />;
  const completedIcon = (
    <div style={iconPositionStyles}>
      <IconContext>
        <Icon
          name="check"
          assistiveText={{ label: 'complete' }}
          category="action"
          size="xx-small"
          containerClassName="tds-bg_success slds-show"
        />
      </IconContext>
    </div>
  );
  const stepTitle = isDisabled ? (
    <h2
      className={`slds-text-heading_small tds-text_bold ${
        isDisabled ? 'tds-color_meteorite' : 'tds-color_black'
      }`}
    >
      {title}
    </h2>
  ) : (
    <a
      href="#"
      style={{ textDecoration: 'none' }}
      onClick={() => (isDisabled ? null : onButtonClick())}
      className={`slds-text-heading_small tds-text_bold ${
        isDisabled ? 'tds-color_meteorite' : 'tds-color_brand'
      }`}
    >
      {title}
    </a>
  );

  return (
    <div className="th-first-step">
      <div className="th-first-step-image">
        {isCompleted ? completedIcon : null}
        <img
          alt=""
          src={imgUrl}
          className={isDisabled ? 'th-first-step-image_grayscale' : null}
        />
        {lastChild ? null : dottedTrail}
      </div>
      <div
        className={`th-first-step-content ${
          lastChild ? '' : 'th-first-step-content_bottom-bordered'
        }`}
      >
        <div>
          <div className="slds-text-title">{section}</div>
          {stepTitle}
          <div className="slds-text-body_small">{subtitle}</div>
        </div>

        <Button
          disabled={isDisabled}
          full={false}
          label={btnText}
          size={null}
          variant="neutral"
          onClick={onButtonClick}
        />
      </div>
    </div>
  );
};

export default FirstStep;
