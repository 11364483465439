import React from 'react';
import FirstStep from './FirstStep';

export default class FirstSteps extends React.Component {
  render() {
    const { steps, stepsTitle } = this.props;
    const stepsLength = steps.length;

    return (
      <div className="th-first-steps-container slds-m-horizontal_medium slds-p-around_medium">
        <div className="slds-text-heading_medium tds-text-size_6 slds-m-top_x-small slds-m-bottom_large">
          {stepsTitle}
        </div>
        <div>
          {steps.map((stepProps, i) => (
            <FirstStep lastChild={i === stepsLength - 1} {...stepProps} />
          ))}
        </div>
      </div>
    );
  }
}
