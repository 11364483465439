import React from "react";
import PropTypes from "prop-types";

import provideContext from "lib/provideContext";

import ChallengeTracking from "metrics/ChallengeTracking";
import Quiz from "./Quiz";
import WebComponent from "../WebComponent";
import AssessmentContainer from "./AssessmentContainer";
import TableauAssessmentContainer from "./TableauAssessmentContainer";
import Complete from "./Complete";
import { t } from "lib/I18n";
import TableauComplete from "./TableauComplete";

import RewardsModal from "./RewardsModal";

const parents = {
  "Th::Unit": "Th::Module",
  "Th::Step": "Th::Project"
};

const analyticsEventMethods = {
  "Th::Unit": "trackUnitCompleted",
  "Th::Step": "trackProjectStepCompleted",
  "Th::Module": "trackModuleCompleted",
  "Th::Project": "trackProjectModuleCompleted"
};

export class Challenge extends React.Component {
  static propTypes = {
    authenticated: PropTypes.bool.isRequired,
    msa_signed: PropTypes.bool.isRequired,
    challenge: PropTypes.shape({
      unit_uid: PropTypes.string,
      content_uid: PropTypes.string,
      api_name: PropTypes.string,
      module_api_name: PropTypes.string,
      questions: PropTypes.array,
      points_earned: PropTypes.number,
      points: PropTypes.number,
      points_next: PropTypes.number,
      completed: PropTypes.bool,
      attempts: PropTypes.number,
      type: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
      requirements: PropTypes.array,
      unit_type: PropTypes.string,
      show_recommendations_on_complete: PropTypes.bool,
      hide_org_picker: PropTypes.bool.isRequired,
      badge_ratings: PropTypes.object.isRequired,
      assessment_evaluation_context: PropTypes.shape({
        type: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        requirements: PropTypes.array.isRequired,
        uuid: PropTypes.string,
        questions: PropTypes.array,
        metadata: PropTypes.shape({
          authorization_requirements: PropTypes.shape({ account_type: PropTypes.string.isRequired })
        }),
        authorized_account_type: PropTypes.string,
        authorized_accounts: PropTypes.array
      }).isRequired,
      preview_mode: PropTypes.bool.isRequired,
      disconnect_mulesoft_anypoint_account_path: PropTypes.string.isRequired,
      trial_cta: PropTypes.object,
      jit_enabled: PropTypes.bool
    }).isRequired,
    next_challenge: PropTypes.object.isRequired,
    sharing: PropTypes.object.isRequired,
    rewards: PropTypes.array,
    tp_help_link: PropTypes.string.isRequired,
    isTrailheadOnPlatform: PropTypes.bool,
    shouldRenderToS: PropTypes.bool,
    shouldRenderMarketingOptIn: PropTypes.bool,
    parent_trailmix: PropTypes.object,
    namespace_id: PropTypes.string
  };

  state = {
    unitCompleted: this.props.challenge.completed,
    rewards: this.props.rewards,
    showRewardsModal: false,
    retake: false,
    actAuthenticated: !!this.props.authenticated || !!this.props.isTrailheadOnPlatform
  };

  componentDidMount() {
    const challengeMountedEvent = document.createEvent("Event");
    challengeMountedEvent.initEvent("challengeMountedEvent", true, true);
    document.dispatchEvent(challengeMountedEvent);

    // Disable the navigation warning if the user tries to navigate away from a completed unit
    if (this.props.challenge.completed) {
      window.dispatchEvent(new CustomEvent("disable_unload_warning"));
    }
    // Detect if the user is retaking the assessment, based on the `retake` query parameter
    // (for Tableau HOCs, where we need to revert the state of the viz by fetching an updated
    // JWT and reloading the iframe)
    const currentUrl = new URL(window.location.href);
    const isRetaking = currentUrl.searchParams.get("retake") === "true";
    if (isRetaking) {
      currentUrl.searchParams.delete("retake");
      window.history.replaceState({}, "", currentUrl.toString());
      this.initiateRetake();
    }
  }

  instrument(rewards) {
    // The first reward is the only one that is relevant for metrics
    const reward = rewards[0];
    const isChild = reward.type === "Th::Step" || reward.type === "Th::Unit";
    if (!isChild) {
      return;
    }

    let eventMethod = analyticsEventMethods[reward.type];
    ChallengeTracking[eventMethod](this.props.challenge.api_name);

    if (reward.module_progress >= 100) {
      eventMethod = analyticsEventMethods[parents[reward.type]];
      ChallengeTracking[eventMethod](this.props.challenge.module_api_name);
    }
  }

  completeChallenge = (unitCompleted, points, rewards) => {
    this.instrument(rewards);

    const isModule = !!rewards.find((r) => r.type === "Th::ModuleBadge");

    document.dispatchEvent(
      new CustomEvent("trailhead", {
        detail: {
          type: "challengeComplete",
          payload: {
            type: isModule ? "module" : this.props.challenge.unit_type
          }
        }
      })
    );
    window.dispatchEvent(new CustomEvent("disable_unload_warning"));

    this.setState({
      unitCompleted,
      points,
      rewards,
      retake: false,
      showRewardsModal: !this.props.isTrailheadOnPlatform
    });
  };

  completedReward = () =>
    this.state.rewards.find((r) => r.type === "Th::Unit" || r.type === "Th::Step" || r.type === "Th::Lesson");

  initiateRetake = () => {
    this.setState({
      retake: true
    });
  };

  completeEnhancedQuiz = (event) => {
    const { assessment_state, legacy_rewards } = event.detail;
    const points_earned = assessment_state.points_earned;
    this.completeChallenge(true, points_earned, legacy_rewards);
  };

  // Converts the enhanced quiz data (passed in as props to this Challenge component as part of unit_props)
  // into a format that can be used by the `tds-enhanced-quiz` component to enable the ability to take
  // an enhanced quiz in preview mode without making additional network calls.
  generateEnhancedQuizAssessment = (challenge, rewards, nextChallenge) => {
    const unitDetails = rewards && rewards[0];
    const challengeDetails = challenge || {};
    const { module_progress, module_title, badge_url } = unitDetails || {};
    const { completed, points, points_next, attempts, points_earned } = challengeDetails;
    const questions = (challenge.assessment_evaluation_context.questions || []).map((questionObj) => {
      const { question, questionID, enhancedOptions } = questionObj;
      return {
        question: question,
        id: questionID,
        options: enhancedOptions.map((enhancedOption) => {
          const { optionID, value, feedback, correct } = enhancedOption;
          return {
            id: optionID,
            label: value,
            feedback,
            correct
          };
        })
      };
    });

    return {
      type: "enhanced_quiz",
      assessment_state: {
        completed,
        points_available: points,
        points_available_next: points_next,
        attempts,
        points_earned,
        content_title: module_title,
        content_progress: module_progress,
        badge_url
      },
      assessment_ui: {
        questions
      },
      // The next_challenge doesn't describe the content type, so we'll use a generic "Continue" label here.
      call_to_action: {
        text: t("reward.button.continue"),
        url: nextChallenge.next_challenge ? nextChallenge.next_challenge.url : "/modules"
      }
    };
  };

  isTableau = () => {
    const evaluationContext = this.props.challenge.assessment_evaluation_context;
    return evaluationContext?.embedded_app_type === "tableau";
  };

  isTableauAccountConnectionRequired = () => {
    const evaluationContext = this.props.challenge.assessment_evaluation_context;
    return this.isTableau() && evaluationContext?.authorized_tableau_public_account?.status !== "valid";
  };

  rewardColorScheme = () => {
    if (this.isTableau()) {
      return "tableau";
    }
    return "default";
  };

  assessmentContainer = () => {
    return (
      <AssessmentContainer
        msaSigned={this.props.msa_signed}
        apiName={this.props.challenge.api_name}
        authenticated={this.state.actAuthenticated}
        points={this.props.challenge.points}
        pointsNext={this.props.challenge.points_next}
        completeChallenge={this.completeChallenge}
        attempts={this.props.challenge.attempts}
        description={this.props.challenge.description}
        requirements={this.props.challenge.requirements}
        title={this.props.challenge.title}
        unitType={this.props.challenge.unit_type}
        retake={this.state.retake}
        tpHelpLink={this.props.tp_help_link}
        hideOrgPicker={this.props.challenge.hide_org_picker}
        parent_trailmix={this.props.next_challenge.parent_trailmix}
        evaluationContext={this.props.challenge.assessment_evaluation_context}
        disconnectMulesoftAnypointAccountPath={this.props.challenge.disconnect_mulesoft_anypoint_account_path}
        previewMode={this.props.challenge.preview_mode}
        unitUid={this.props.challenge.unit_uid}
        jitEnabled={this.props.challenge.jit_enabled}
      />
    );
  };

  tableauAssessmentContainer = () => {
    return (
      <TableauAssessmentContainer
        msaSigned={this.props.msa_signed}
        apiName={this.props.challenge.api_name}
        authenticated={this.state.actAuthenticated}
        points={this.props.challenge.points}
        pointsNext={this.props.challenge.points_next}
        completeChallenge={this.completeChallenge}
        attempts={this.props.challenge.attempts}
        description={this.props.challenge.description}
        requirements={this.props.challenge.requirements}
        title={this.props.challenge.title}
        unitType={this.props.challenge.unit_type}
        retake={this.state.retake}
        tpHelpLink={this.props.tp_help_link}
        parent_trailmix={this.props.next_challenge.parent_trailmix}
        evaluationContext={this.props.challenge.assessment_evaluation_context}
        contentUid={this.props.challenge.content_uid}
        unitUid={this.props.challenge.unit_uid}
      />
    );
  };

  renderChallenge = () => {
    const evaluationContext = this.props.challenge.assessment_evaluation_context;
    if (this.state.unitCompleted && !this.state.retake && !evaluationContext.enhanced_quiz_options_supported) {
      if (this.isTableau()) {
        return <TableauComplete reward={this.completedReward()} />;
      }
      return (
        <Complete
          reward={this.completedReward()}
          nextChallenge={this.props.next_challenge}
          retakeable={this.props.challenge.type === "assessment"}
          initiateRetake={this.initiateRetake}
          unitType={this.props.challenge.unit_type}
          isTrailheadOnPlatform={!!this.props.isTrailheadOnPlatform}
        />
      );
    }

    if (evaluationContext.type == "hoc") {
      return this.isTableau() ? this.tableauAssessmentContainer() : this.assessmentContainer();
    }
    else if (evaluationContext.type == "mcq") {
      // Render the enhanced quiz web component, but only if we're not rendering in a Trailhead On Platform iframe
      if (evaluationContext.enhanced_quiz_options_supported && !this.props.isTrailheadOnPlatform) {
        const events = {
          complete: this.completeEnhancedQuiz
        };

        if (this.props.challenge.preview_mode) {
          return (
            <WebComponent
              tagName="tds-enhanced-quiz"
              attrs={{
                preview: true,
                desktopOnly: evaluationContext.embedded_app_type === "tableau",
                connectedAccountRequired: this.isTableauAccountConnectionRequired(),
                assessment: this.generateEnhancedQuizAssessment(
                  this.props.challenge,
                  this.props.rewards,
                  this.props.next_challenge
                )
              }}
              events={events}
            />
          );
        }
        else {
          return (
            <WebComponent
              tagName="th-enhanced-quiz"
              attrs={{
                desktopOnly: evaluationContext.embedded_app_type === "tableau",
                connectedAccountRequired: this.isTableauAccountConnectionRequired(),
                contentId: this.props.challenge.content_uid
              }}
              events={events}
            />
          );
        }
      }
      else {
        return (
          <Quiz
            apiName={this.props.challenge.api_name}
            content_uid={this.props.challenge.content_uid}
            authenticated={this.state.actAuthenticated}
            questions={this.props.challenge.questions}
            points={this.props.challenge.points}
            pointsNext={this.props.challenge.points_next}
            completeChallenge={this.completeChallenge}
            attempts={this.props.challenge.attempts}
            parent_trailmix={this.props.next_challenge.parent_trailmix}
            isTrailheadOnPlatform={this.props.isTrailheadOnPlatform}
            shouldRenderToS={this.props.shouldRenderToS}
            shouldRenderMarketingOptIn={this.props.shouldRenderMarketingOptIn}
          />
        );
      }
    }
  };

  resetFocus = () => {
    document.querySelector(".th-challenge-complete").focus();
  };

  render() {
    return (
      <div>
        {this.renderChallenge()}
        {this.state.rewards && this.state.showRewardsModal && (
          <RewardsModal
            apiName={this.props.challenge.api_name}
            moduleApiName={this.props.challenge.module_api_name}
            contentUid={this.props.challenge.content_uid}
            colorScheme={this.rewardColorScheme()}
            moduleUid={this.props.challenge.module_uid}
            unitType={this.props.challenge.unit_type}
            rewards={this.state.rewards}
            nextChallenge={this.props.next_challenge}
            sharing={this.props.sharing}
            showRecommendations={this.props.challenge.show_recommendations_on_complete}
            badgeRatings={this.props.challenge.badge_ratings}
            trialCta={this.props.challenge.trial_cta}
            onClose={this.resetFocus}
            namespaceId={this.props.namespace_id}
          />
        )}
      </div>
    );
  }
}

export default provideContext(Challenge);
