import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'lib/I18n';
import get from 'lodash/get';
import Spinner from 'components/utils/Spinner';
import LoginModalBtn from 'components/auth/buttons/LoginModalBtn';

export default class ChallengeFooter extends React.Component {
  static propTypes = {
    points: PropTypes.number.isRequired,
    authenticated: PropTypes.bool.isRequired,
    isSubmitDisabled: PropTypes.func.isRequired,
    renderHelperText: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    unitType: PropTypes.string.isRequired,
    onSubmit: PropTypes.func,
    isSubmitting: PropTypes.bool,
    selectedOrg: PropTypes.object,
    loginClassName: PropTypes.string,
    evaluationContext: PropTypes.object,
  };

  static defaultProps = {
    onSubmit: () => undefined,
    loginClassName: '',
  };

  renderSubmitButton = () => (
    <button
      type="submit"
      disabled={this.props.isSubmitDisabled()}
      onClick={this.props.onSubmit}
      className="slds-button th-button th-button--success th-challenge__footer-cta"
      data-test={`${this.props.type}-${this.props.unitType}-challenge-check`}
    >
      {this.props.isSubmitting && (
        <Spinner inline={true} className="slds-spinner_x-small" />
      )}
      {t(`challenge.${this.props.type}.button.${this.props.unitType}`, {
        points: this.props.points,
      })}
    </button>
  );

  renderErrorTitle = () => {
    if (this.props.type === 'assessment') {
      if (this.props.evaluationContext?.authorized_account_type === 'mulesoft_anypoint_account') {
        return t(`challenge.assessment.mulesoft.error.${this.props.unitType}`);
      }
      return t(`challenge.${this.props.type}.error.title`, {
        type: t(`challenge.${this.props.type}.type.${this.props.unitType}`),
        tp_name: get(this.props.selectedOrg, 'name'),
      });
    }
    return t(`challenge.${this.props.type}.error.title`);
  };

  renderAssessmentLayout = () => {
    const helperText = this.props.renderHelperText();

    return (
      <div>
        {helperText && (
          <div role="alert" className="slds-text-color_error slds-m-bottom_large">
            {this.props.type !== 'quiz' && (
              <div className="tds-text_bold">
                {this.renderErrorTitle()}
              </div>
            )}
            <div className="tds-text_italic slds-m-top_x-small">{helperText}</div>
          </div>
        )}

        {this.renderSubmitButton()}
      </div>
    );
  };

  renderQuizLayout = () => (
    <div className="th-challenge__quiz-footer">
      {this.props.renderHelperText()}
      {this.renderSubmitButton()}
    </div>
  )

  render() {
    if (!this.props.authenticated) {
      return (
        <div className="th-challenge__footer">
          <LoginModalBtn
            className={`slds-button slds-button_brand ${this.props.loginClassName}`}
            content={t(
              `challenge.${this.props.type}.button.login.${this.props.unitType}`
            )}
          />
        </div>
      );
    }

    return (
      <div className="th-challenge__footer">
        {this.props.type === 'quiz' ?
          this.renderQuizLayout() :
          this.renderAssessmentLayout()}
      </div>
    );
  }
}
