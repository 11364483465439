import track from '../../lib/tracking/track';

// Value Modal

export const trackValuePropModalStart = () => {
  const eventName = 'modal_started';
  const payload = {
    page_location: 'onboarding_step',
    name: 'value_prop_modal',
  };
  track(eventName, payload);
};

export const trackValuePropModalClose = () => {
  const eventName = 'button_clicked';
  const payload = {
    label: 'close',
    page_location: 'onboarding_value_prop_modal',
  };
  track(eventName, payload);
};

export const trackValuePropModalCompleted = () => {
  const eventName = 'modal_completed';
  const payload = {
    page_location: 'onboarding_value_prop_modal',
    name: 'value_prop_modal',
  };
  track(eventName, payload);
};

export const trackValuePropModalScreenAdvancement = (label) => {
  const eventName = 'button_clicked';
  const payload = {
    page_location: 'onboarding_value_prop_modal',
    label,
  };
  track(eventName, payload);
};

// Onboarding Step

export const trackStepClicked = ({ step, label }) => {
  const eventName = 'onboarding_step_clicked';
  const payload = {
    page_location: 'onboarding_step',
    step,
    label,
  };
  track(eventName, payload);
};

// Resize

export const trackResizeDismiss = () => {
  const eventName = 'modal_disappeared';
  const payload = {
    name: 'value_prop_modal',
  };
  track(eventName, payload);
};
