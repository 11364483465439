import React, { Component } from 'react';
import railsFetch from 'lib/railsFetch';
import FirstSteps from './onboarding_experiment/FirstSteps';
import RankProgress from './onboarding_experiment/RankProgress';
import ValueModal from './onboarding_experiment/ValueModal';
import {
  trackStepClicked,
  trackResizeDismiss,
  trackValuePropModalStart,
  trackValuePropModalClose,
  trackValuePropModalCompleted,
  trackValuePropModalScreenAdvancement,
} from './onboarding_experiment/tracking';
import OnboardingErrorBoundary from './onboarding_experiment/OnboardingErrorBoundary';

export default class FirstExperience extends Component {
  constructor(props) {
    super(props);
    const { steps } = this.props;

    this.state = {
      isValueModalOpen: false,
      steps,
    };
  }

  componentDidMount = () => {
    this.prefetchImages();
  };

  prefetchImages() {
    const { valueModalScreens } = this.props;
    const imageUrls = valueModalScreens.map((s) => s.imgUrl);
    this.valuePropImages = imageUrls.forEach((imageUrl) => {
      const img = new Image();
      img.src = imageUrl;
    });
  }

  trackValuePropModalToggle = (completed) => {
    if (this.state.isValueModalOpen) {
      // eslint-disable-next-line no-unused-expressions
      completed ? trackValuePropModalCompleted() : trackValuePropModalClose();
    } else {
      trackStepClicked({
        step: 'value_prop_modal',
        label: 'read',
      });
      trackValuePropModalStart();
    }
  };

  valuePropModalOnClick = () => {
    this.toggleValueModal({});
    this.completeStep('value_prop_modal');
  };

  basicsOnClick = () => {
    trackStepClicked({ step: 'th_basics', label: 'learn' });
    window.location.href = this.props.thBasicsUrl;
  };

  stepsPropsWithOnClicks() {
    const { steps } = this.state;
    const onClickHandlers = [this.valuePropModalOnClick, this.basicsOnClick];

    return steps.map((step, i) => ({
      ...step,
      ...{ onButtonClick: onClickHandlers[i] },
    }));
  }

  toggleValueModal = ({ completed = false, disappeared = false }) => {
    if (!disappeared) {
      this.trackValuePropModalToggle(completed);
    }
    this.setState({ isValueModalOpen: !this.state.isValueModalOpen });
  };

  currentBarWidth() {
    const { steps } = this.state;
    const stepsLength = steps.length;
    const completedSteps = this.completedSteps();
    let muliplier = completedSteps;

    if (completedSteps === 0) {
      muliplier = 0.3;
    }

    return (100 / stepsLength) * muliplier;
  }

  completedStepIndex = (stepName) =>
    this.state.steps.findIndex((step) => step.key === stepName);

  updateStepsState = (stepName) => {
    const steps = [...this.state.steps];
    const stepIndex = this.completedStepIndex(stepName);
    const enabledStepIndex = stepIndex + 1;
    const completedStep = { ...steps[stepIndex], ...{ isCompleted: true } };
    const enabledStep = {
      ...steps[enabledStepIndex],
      ...{ isDisabled: false },
    };

    if (steps[enabledStepIndex]) {
      steps[enabledStepIndex] = enabledStep;
    }
    steps[stepIndex] = completedStep;
    this.setState({ steps });
  };

  completeStep(stepName) {
    railsFetch({
      data: { onboarding: { step_name: stepName } },
      url: '/complete_onboarding_step',
      method: 'post',
    })
      .then(() => {
        this.updateStepsState(stepName);
      })
      .catch((err) => console.error(err));
  }

  completedSteps() {
    const { steps } = this.state;

    return steps.reduce(
      (counter, { isCompleted }) => (isCompleted ? counter + 1 : counter),
      0,
    );
  }

  render() {
    const { stepsTitle, rank, valueModalScreens } = this.props;
    const { isValueModalOpen } = this.state;

    return (
      <OnboardingErrorBoundary>
        <div>
          <div
            className="slds-container_medium slds-container_center slds-p-bottom_x-large"
            style={{ minHeight: '600px' }}
          >
            <div>
              {isValueModalOpen && (
                <ValueModal
                  isOpen={isValueModalOpen}
                  screens={valueModalScreens}
                  onClose={this.toggleValueModal}
                  trackValuePropModalScreenAdvancement={
                    trackValuePropModalScreenAdvancement
                  }
                  trackResizeDismiss={trackResizeDismiss}
                />
              )}
              <FirstSteps
                steps={this.stepsPropsWithOnClicks()}
                stepsTitle={stepsTitle}
              />
              <div className="th-simplified-homepage tds-dotted-trail" />
              <RankProgress barWidth={this.currentBarWidth()} {...rank} />
            </div>
          </div>
        </div>
      </OnboardingErrorBoundary>
    );
  }
}
