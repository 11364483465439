import React from 'react';
import PropTypes from 'prop-types';
import Form from 'components/form/Form';
import IconContext from 'components/utils/IconContext';
import { Card, ColorPicker, Icon, Toast, Tooltip, VerticalNavigation } from '@devforce/tds-react';
import { t } from 'lib/I18n';
import provideContext from 'lib/provideContext';
import wrapProvider from 'lib/wrapProvider';
import { cancelBrandingEdit, initBranding } from 'actions/trailmakerActions';
import { store } from 'reducers';
import BrandImagePicker from 'components/form/BrandImagePicker';
import ExportSettings from './ExportSettings';
import NavigationSettings from './NavigationSettings';
import RatingsSettings from './RatingsSettings';
import CategorySettings from './CategorySettings';
import ActionButtons from './lib/ActionButtons';
import ConfirmationModal from './lib/ConfirmationModal';
import AbandonModal from './lib/AbandonModal';
import VerticalNavigationItems from './NavigationItems';

const BRAND_IMG_SIZE_LIMIT_MB = 5;
const BRANDING_ACCEPTED_IMAGE_FORMATS = 'image/png,image/gif,image/jpeg,image/jpg,.jpg,.png,.gif,.jpeg';

export class Settings extends React.PureComponent {
  static propTypes = {
    branding: PropTypes.shape({
      color: PropTypes.string,
      brandLogo: PropTypes.string,
      homeBanner: PropTypes.string,
    }),
    brandingPath: PropTypes.string,
    isLoading: PropTypes.bool,
    ok: PropTypes.bool,
    siteUrl: PropTypes.string,
    publicTrailmaker: PropTypes.bool,
  };

  static defaultProps = {
    isLoading: false,
    publicTrailmaker: false,
    showBadgeRatings: false,
    showBadgeRatingsExport: false,
    showContentExport: false,
  };

  state = {
    brandLogo: this.props.branding.brandLogo,
    homeBanner: this.props.branding.homeBanner,
    color: this.props.branding.color,
    brandImageFile: null,
    saveModalOpen: false,
    errorMessage: null,
    publishBtnDisabled: false,
    currentPageId: null,
    pristine: true,
    abandonModalOpen: false,
    targetSubPageId: null,
  };

  componentWillMount() {
    this.persistedBranding = this.props.branding;
  }

  componentDidMount() {
    if (window) {
      window.addEventListener('beforeunload', this.onBeforeUnload);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.ok && !nextProps.isLoading) {
      window.location.reload();

      // persistedBranding is updated when save succeed
      this.persistedBranding = nextProps.branding;
    }
  }

  onBeforeUnload = (e) => {
    if (this.state.pristine === false) {
      // Ask for confirmation:
      e.preventDefault();
      // Chrome requires returnValue to be set
      e.returnValue = '';
    }
  };

  onSaveBranding = (data) => {
    this.setState({
      saveModalOpen: true,
      formData: data
    });
  };

  onColorChange = (_event, { color, isValid }) => {
    this.setState({ color, publishBtnDisabled: !isValid });
    if (isValid) {
      this.setState({pristine: false});
    }
  };

  onImageChange = ({
    brandImage,
    brandImageFile,
    imageKey,
  }) => {
    this.setState({
      [imageKey]: brandImage,
      brandImageFile,
      pristine: false,
    });
  };

  onLogoImageChange = ({
    brandImage,
    brandImageFile,
  }) => {
    this.onImageChange({
      brandImage,
      brandImageFile,
      imageKey: 'brandLogo'
    });
  };

  onBannerImageChange = ({
    brandImage,
    brandImageFile,
  }) => {
    this.onImageChange({
      brandImage,
      brandImageFile,
      imageKey: 'homeBanner'
    });
  };

  onBrandImageError = (message) => {
    this.setState({ errorMessage: message });
  };

  onCloseToast = () => {
    this.setState({ errorMessage: null });
  };

  onSubPageNavigation = (pageId) => {
    if (this.state.currentPageId === pageId) return;

    if (!this.state.pristine) {
      this.setState({targetSubPageId: pageId, abandonModalOpen: true});
    } else {
      this.switchSubPage(pageId);
    }
  };

  getCurrentPageName = () => {
    const {
      currentPageId,
      publicTrailmaker,
      showBadgeRatings,
      showContentExport,
    } = this.props;
    const nav_items =
        VerticalNavigationItems(
          { publicTrailmaker, showBadgeRatings, showContentExport }
        );

    if (!currentPageId) return '';

    return (
      nav_items[0].items.find((i) => i.id === currentPageId).label
    );
  }

  resetBranding = () => {
    this.setState({
      brandLogo: this.persistedBranding.brandLogo,
      homeBanner: this.persistedBranding.homeBanner,
      color: this.persistedBranding.color,
      errorMessage: null,
      pristine: true,
    });
  }

  saveBrandingSettings = () => {
    // Need this hack because updating the state would not complete before the form submits :-/
    window.removeEventListener('beforeunload', this.onBeforeUnload);
    this.settingsForm.form.submit();
  };

  switchSubPage = (pageId) => {
    this.resetBranding();
    this.setState({currentPageId: pageId});
  };

  renderSideMenu = () => {
    const {
      publicTrailmaker,
      showBadgeRatings,
      showContentExport,
    } = this.props;
    const nav_items =
        VerticalNavigationItems(
          { publicTrailmaker, showBadgeRatings, showContentExport }
        );

    return (
      <div
        className="slds-col slds-size_1-of-1 slds-small-size_1-of-1 slds-medium-size_1-of-4 slds-large-size_1-of-5 slds-is-relative"
      >
        <div className="tds-is-sticky css-12uuxr4">
          <Card flush={true}>
            <VerticalNavigation
              categories={nav_items}
              selectedId={this.state.currentPageId || nav_items[0].items[0].id}
              className="slds-p-bottom_small"
              onSelect={(_, {item}) => this.onSubPageNavigation(item.id)}
            />
          </Card>
        </div>
      </div>
    );
  }

  renderToast = () => {
    const { errorMessage } = this.state;

    return !!errorMessage && (
      <Toast
        labels={{
          heading: errorMessage
        }}
        variant="error"
        onRequestClose={this.onCloseToast}
      />
    );
  };

  renderTooltip({
    content,
    assistiveTextLabel,
    dataTestName
  }) {
    return (
      <Tooltip
        align="top left"
        content={content}
        dialogClassName="th-min-width--medium"
      >
        <div tabIndex="0" data-test={dataTestName}>
          <Icon
            assistiveText={{
              label: assistiveTextLabel
            }}
            category="utility"
            name="info"
            size="x-small"
          />
        </div>
      </Tooltip>
    );
  }

  renderBrandLogoPicker() {
    return (
      <div className="slds-m-bottom_xx-large">
        <label htmlFor="brand-image-picker" className="slds-m-right_small">
          {t('views.trailmaker.settings.branding.brand_logo.label')}
        </label>
        {this.renderTooltip({
          content: t('views.trailmaker.settings.branding.brand_logo.tooltip'),
          assistiveTextLabel: t('views.trailmaker.settings.branding.brand_logo.tooltip_assistive'),
          dataTestName: 'brand-image-tooltip'
        })}
        <BrandImagePicker
          brandImage={this.state.brandLogo}
          onImageChange={this.onLogoImageChange}
          acceptedFormats={BRANDING_ACCEPTED_IMAGE_FORMATS}
          imageSizeLimitInMbs={BRAND_IMG_SIZE_LIMIT_MB}
          fieldName="setting[brand_logo]"
          errorMsgSize={t('views.trailmaker.settings.branding.brand_logo.error_size', { limit: BRAND_IMG_SIZE_LIMIT_MB })}
          onError={this.onBrandImageError}
          id="brand-image-picker"
        >
          <div
            className="slds-grid slds-grid_align-center slds-grid_vertical-align-center"
            style={{
              border: '1px solid #e2e2e2',
              height: '100px',
              overflow: 'hidden',
              position: 'relative'
            }}
          >
            <img
              src={this.state.brandLogo}
              alt={t('views.trailmaker.settings.branding.brand_logo.alt_icon_text')}
            />
            <div className="th-image-edit-hover th-image-edit-hover--small" />
          </div>
        </BrandImagePicker>
      </div>
    );
  }

  renderBrandColorPicker() {
    return (
      <div className="slds-m-bottom_xx-large">
        <div>
          <label htmlFor="default-color-picker" className="slds-m-right_small">
            {t('views.trailmaker.settings.branding.brand_color.label')}
          </label>
          {this.renderTooltip({
            content: t('views.trailmaker.settings.branding.brand_color.tooltip'),
            assistiveTextLabel: t('views.trailmaker.settings.branding.brand_color.tooltip_assistive'),
            dataTestName: 'brand-color-tooltip'
          })}
          <ColorPicker
            id="default-color-picker"
            value={this.state.color}
            events={{ onChange: this.onColorChange }}
          />
          <input name="setting[color]" type="hidden" value={this.state.color} />
        </div>
      </div>
    );
  }

  renderBannerPicker() {
    return (
      <div>
        <p className="slds-text-body_regular slds-m-bottom_small slds-m-right_small slds-show_inline">
          {t('views.trailmaker.settings.branding.home_page_banner.label')}
        </p>
        {this.renderTooltip({
          content: t('views.trailmaker.settings.branding.home_page_banner.tooltip'),
          assistiveTextLabel: t('views.trailmaker.settings.branding.home_page_banner.tooltip_assistive'),
          dataTestName: 'home_page_banner-tooltip'
        })}
        <p className="slds-text-body_small slds-m-top_medium slds-m-bottom_medium">
          {t('views.trailmaker.settings.branding.home_page_banner.instructions')}
        </p>
        <div className="slds-is-relative">
          <BrandImagePicker
            brandImage={this.state.homeBanner}
            onImageChange={this.onBannerImageChange}
            acceptedFormats={BRANDING_ACCEPTED_IMAGE_FORMATS}
            imageSizeLimitInMbs={BRAND_IMG_SIZE_LIMIT_MB}
            errorMsgSize={t('views.trailmaker.settings.branding.brand_logo.error_size', { limit: BRAND_IMG_SIZE_LIMIT_MB })}
            fieldName="setting[home_banner_image]"
            onError={this.onBrandImageError}
            id="home-banner-image-picker"
          >
            <div
              className="th-hero--custom"
              style={{
                backgroundImage: `url(${this.state.homeBanner})`
              }}
              alt="branding banner preview"
            />
            <div className="th-image-edit-hover" />
          </BrandImagePicker>
        </div>
      </div>
    );
  }

  renderBrandingSettings() {
    const { brandingPath, siteUrl } = this.props;
    return (
      <Form
        /* eslint-disable no-return-assign */
        ref={(form) => this.settingsForm = form}
        encType="multipart/form-data"
        method="put"
        id="settings-form"
        action={brandingPath}
        className="slds-form"
      >
        <div className="slds-text-title slds-m-bottom_large">{t('views.trailmaker.settings.branding.intro')}</div>
        <div className="slds-col slds-grid slds-gutters slds-wrap">
          <div
            className="slds-col slds-large-size--2-of-8 slds-medium-size_2-of-4 slds-small-size_1-of-1">
            {this.renderBrandLogoPicker()}
          </div>
          <div
            className="slds-col slds-large-size--3-of-8 slds-medium-size_2-of-4 slds-small-size_1-of-1">
            {this.renderBrandColorPicker()}
          </div>
        </div>
        {this.renderBannerPicker()}
        <ActionButtons onSave={this.onSaveBranding} onCancel={this.resetBranding} />
        <ConfirmationModal
          onSave={this.saveBrandingSettings}
          onCancel={() => this.setState({saveModalOpen: false, formData: null})}
          heading={t('views.trailmaker.settings.branding.save_prompt_header')}
          body={t('views.trailmaker.settings.branding.save_prompt', { url: siteUrl })}
          isOpen={this.state.saveModalOpen}
        />
      </Form>
    );
  }

  render() {
    const { currentPageId, targetSubPageId } = this.state;
    const {
      publicTrailmaker,
      showBadgeRatings,
      showBadgeRatingsExport,
      showContentExport,
      bypassContentExportLimit,
    } = this.props;
    const nav_items =
        VerticalNavigationItems(
          { publicTrailmaker,
            showBadgeRatings,
            showContentExport
          }
        );

    let pageTitle;
    let pageBody;
    const pageId = currentPageId || nav_items[0].items[0].id;

    switch (pageId) {
      case 'category':
        pageTitle = t('views.trailmaker.settings.menu.category');
        pageBody = <CategorySettings />;
        break;

      case 'roles':
        pageTitle = t('views.trailmaker.settings.menu.category');
        pageBody = <CategorySettings categoryGroupAPIName="roles" publicTrailmaker={publicTrailmaker} />;
        break;

      case 'levels':
        pageTitle = t('views.trailmaker.settings.menu.category');
        pageBody = <CategorySettings categoryGroupAPIName="levels" publicTrailmaker={publicTrailmaker} />;
        break;

      case 'products':
        pageTitle = t('views.trailmaker.settings.menu.category');
        pageBody = <CategorySettings categoryGroupAPIName="products" publicTrailmaker={publicTrailmaker} />;
        break;

      case 'skills':
        pageTitle = t('views.trailmaker.settings.menu.category');
        pageBody = <CategorySettings categoryGroupAPIName="skills" publicTrailmaker={publicTrailmaker} />;
        break;

      case 'industries':
        pageTitle = t('views.trailmaker.settings.menu.category');
        pageBody = <CategorySettings categoryGroupAPIName="industries" publicTrailmaker={publicTrailmaker} />;
        break;

      case 'intent':
        pageTitle = t('views.trailmaker.settings.menu.category');
        pageBody = <CategorySettings categoryGroupAPIName="intent" publicTrailmaker={publicTrailmaker} />;
        break;

      case 'navigation':
        pageTitle = t('views.trailmaker.settings.menu.navigation');
        pageBody = <NavigationSettings setPristine={(v) => this.setState({pristine: v})} />;
        break;

      case 'branding':
        pageTitle = t('views.trailmaker.settings.branding.title');
        pageBody = this.renderBrandingSettings();
        break;

      case 'ratings':
        pageTitle = t('views.trailmaker.settings.menu.ratings');
        pageBody = <RatingsSettings showBadgeRatingsExport={showBadgeRatingsExport} />;
        break;

      case 'export':
        pageTitle = t('views.trailmaker.settings.export.title');
        pageBody = <ExportSettings bypassContentExportLimit={bypassContentExportLimit} />;
        break;

      default:
        pageTitle = '';
        pageBody = '';
    }

    return (
      <IconContext>
        <div className="slds-grid slds-p-around_large slds-wrap">
          {this.renderSideMenu()}
          <div className="slds-col slds-size_1-of-1 slds-small-size_1-of-1 slds-medium-size_3-of-4 slds-large-size_4-of-5">
            <div className="slds-m-left_large">
              <Card title={pageTitle} className="tm-card">
                {pageBody}
              </Card>
            </div>
          </div>
          {this.renderToast()}
          <AbandonModal
            onConfirm={() => {
              this.setState({abandonModalOpen: false, pristine: true});
              this.switchSubPage(targetSubPageId);
            }}
            onCancel={() => this.setState({abandonModalOpen: false, targetSubPageId: null})}
            isOpen={this.state.abandonModalOpen}
            currentPageName={this.getCurrentPageName()}
          />
        </div>
      </IconContext>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    cancelEditing(branding) {
      dispatch(cancelBrandingEdit({ branding }));
    }
  };
}

export default provideContext(
  wrapProvider({
    store,
    initAction: initBranding,
    mapStateToProps: (state) => (state.trailmaker.branding),
    mapDispatchToProps
  })(Settings)
);
