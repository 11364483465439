/* eslint-disable no-return-assign */
import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'lib/I18n';

const ONE_MB = 1048576;

export default class BrandImagePicker extends React.PureComponent {
  static propTypes = {
    onImageChange: PropTypes.func,
    onError: PropTypes.func,
    fieldName: PropTypes.string,
    imageSizeLimitInMbs: PropTypes.number,
    id: PropTypes.string,
    errorMsgSize: PropTypes.string,
    acceptedFormats: PropTypes.string,
    children: PropTypes.node,
  };

  state = {
    fieldName: ''
  };

  onBrandImageClick = () => {
    this.fileInput.click();
  };

  onImageSelected = (_event) => {
    const {
      imageSizeLimitInMbs,
      onImageChange,
      errorMsgSize,
      onError
    } = this.props;
    const file = _event.target.files[0];

    if (!file) {
      return;
    }

    if (file.size > ONE_MB * imageSizeLimitInMbs) {
      onError(errorMsgSize);
    } else {
      const fr = new FileReader();
      fr.onload = (f) => {
        onImageChange({
          brandImage: f.target.result,
          brandImageFile: file
        });
      };
      fr.readAsDataURL(file);
    }
  };

  render() {
    return (
      <div id={this.props.id}>
        <div
          style={{margin: 0}}
          role="button"
          tabIndex={0}
          onClick={this.onBrandImageClick}
          aria-label={t('views.trailmaker.settings.branding.home_page_banner.button_alt_text')}
        >
          { this.props.children }
        </div>

        <input
          ref={(element) => this.fileInput = element}
          className="slds-hide"
          type="file"
          name={this.props.fieldName}
          id={`brandImageInput-${this.props.id}`}
          accept={this.props.acceptedFormats}
          onChange={this.onImageSelected}
        />
      </div>
    );
  }
}

