import React, { Component } from 'react';
import { Button } from '@devforce/tds-react';

class OnboardingErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div
          className="slds-container_medium slds-text-align_center slds-container_center slds-p-bottom_x-large"
          style={{ minHeight: '600px' }}
        >
          <h1>Uh-oh... Something went wrong</h1>

          <Button
            className={null}
            label="Click to reload!"
            size="medium"
            variant="neutral"
            onClick={() => window.location.reload(false)}
          />
        </div>
      );
    }

    return this.props.children;
  }
}
export default OnboardingErrorBoundary;
