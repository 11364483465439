import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@salesforce/design-system-react';

import { t } from 'lib/I18n';
import BadgeSharing from './BadgeSharing';

export default class RewardDetails extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    badgeName: PropTypes.string.isRequired,
    iconUrl: PropTypes.string.isRequired,
    assistiveText: PropTypes.object.isRequired,
    sharing: PropTypes.object,
    pointsBreakdown: PropTypes.object,
    next: PropTypes.object,
    survey: PropTypes.object
  }

  static defaultProps = {
    sharing: undefined,
    pointsBreakdown: undefined,
    next: undefined,
    survey: undefined
  }

  renderHeading() {
    const {title} = this.props;

    return (
      <h2 className="th-text th-text--large tds-text_neutraface">
        {title}
      </h2>
    );
  }

  renderBadgeName() {
    const {badgeName} = this.props;

    return (
      <h3 className="th-text--bold th-text--default th-color--black slds-p-bottom_medium">
        {badgeName}
      </h3>
    );
  }

  renderSubtitle() {
    const {subtitle} = this.props;

    return (
      <h3 className="th-text th-text--small th-color--black">
        {subtitle}
      </h3>
    );
  }

  renderIcon() {
    const {
      title,
      iconUrl,
      assistiveText: {iconLabel}
    } = this.props;

    return (
      <div className="th-badge-reward">
        <img
          className="th-badge-reward__image"
          src={iconUrl}
          alt={title}
        />
        <Icon
          containerClassName="th-badge-reward__state"
          assistiveText={{label: iconLabel}}
          category="action"
          name="approval"
          size="small"
        />
      </div>
    );
  }

  renderSharing() {
    const {sharing} = this.props;

    return sharing && sharing.public && (
      <div className="slds-text-align--center">
        <BadgeSharing sharing={sharing} />
      </div>
    );
  }

  renderSurvey() {
    if (!this.props.survey) {
      return null;
    }

    const {
      survey: {title, url, link_text}
    } = this.props;

    return (
      <div>
        <h3 className="th-text--bold th-text--small th-color--black slds-m-top--medium">
          {title}
        </h3>
        <h4 className="th-text th-text--small slds-m-top--medium">
          <a
            href={url}
            rel="noopener noreferrer"
            target="_blank">
            {link_text}
          </a>
        </h4>
      </div>
    );
  }

  renderNext() {
    const {next} = this.props;

    return next && next.type && (
      <a
        className="slds-button th-button th-button--medium th-button--primary slds-m-bottom--medium"
        href={next.url}>
        {t(`views.client.check_challenge.complete.next_${next.type}`).replace(':', '')}
      </a>
    );
  }

  render() {
    return (
      <div className="slds-text-align--center">
        {this.renderHeading()}
        {this.renderIcon()}
        {this.renderBadgeName()}
        {this.renderSubtitle()}
        {this.props.pointsBreakdown}
        {this.renderSharing()}
        {this.renderSurvey()}
        {this.renderNext()}
      </div>
    );
  }
}
