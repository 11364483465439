import React from 'react';
import { Modal } from '@devforce/tds-react';
import { Settings } from '@salesforce/design-system-react';
import IconContext from 'components/utils/IconContext';

Settings.setAppElement('#main-wrapper');

export default class ValueModal extends React.Component {
  state = { currentScreen: 0 };

  advanceScreen = (btnText) => {
    const {
      screens,
      onClose,
      trackValuePropModalScreenAdvancement,
    } = this.props;
    const { currentScreen } = this.state;
    const lastScreen = screens.length - 1;

    trackValuePropModalScreenAdvancement(btnText);
    this.setFocus();

    if (currentScreen === lastScreen) {
      this.setState({ currentScreen: 0 });
      return onClose({ completed: true, disappeared: false });
    }

    return this.setState({ currentScreen: currentScreen + 1 });
  };

  scrollModalContentToTop() {
    if (window.innerWidth < 766) {
      const modalContent = document.getElementsByClassName(
        'th-value-prop-modal-container',
      )[0];
      // eslint-disable-next-line no-unused-expressions
      modalContent && modalContent.parentElement.scrollTo({ top: 0 });
    }
  }

  setFocus() {
    const title = document.querySelector('.th-value-prop-modal__title');
    title?.focus();
  }

  renderProgressDot = (completed) => (
    <span className={`dot ${completed ? 'dot__completed' : ''}`} />
  );

  renderScreen = () => {
    const { screens } = this.props;
    const { currentScreen } = this.state;
    const { imgUrl, title, content } = screens[currentScreen];
    this.scrollModalContentToTop();
    return (
      <div className="th-value-prop-modal-container__grid">
        <div className="slds-m-horizontal_large th-value-prop-modal-container__content">
          <div tabIndex="0" className="tds-text-heading_neutraface-large tds-slds-text-align_left slds-m-top_xx-large th-value-prop-modal__title">
            {title}
          </div>
          <p
            style={{ whiteSpace: 'pre-wrap' }}
            className="th-text--default slds-m-vertical_medium"
          >
            {content}
          </p>
        </div>
        <div
          className="th-value-prop-modal-container__img"
          style={{
            backgroundImage: `url(${imgUrl})`,
          }}
        />
      </div>
    );
  };

  render() {
    const { isOpen, onClose, screens } = this.props;
    const { currentScreen } = this.state;
    const { btnText } = screens[currentScreen];

    const nextBtn = (
      <button
        onClick={() => this.advanceScreen(btnText)}
        className="slds-button slds-button_brand tds-button_brand"
        type="button"
        style={{ position: 'absolute', right: '15px', alignSelf: 'center' }}
      >
        {btnText}
      </button>
    );

    const progress = (
      <div style={{ alignSelf: 'center' }}>
        <span>{this.renderProgressDot(currentScreen >= 0)}</span>
        <span>{this.renderProgressDot(currentScreen >= 1)}</span>
        <span>{this.renderProgressDot(currentScreen >= 2)}</span>
        <span>{this.renderProgressDot(currentScreen >= 3)}</span>
        <span>{this.renderProgressDot(currentScreen >= 4)}</span>
      </div>
    );

    return (
      <IconContext>
        <Modal
          isOpen={isOpen}
          onRequestClose={() => onClose({})}
          dismissOnClickOutside={false}
        >
          <div className="th-value-prop-modal-container">
            {this.renderScreen()}
            <div className="th-value-prop-modal-container__footer">
              {progress}
              {nextBtn}
            </div>
          </div>
        </Modal>
      </IconContext>
    );
  }
}
